




























































import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {
  Device as DeviceService,
  Location as LocationService,
} from "@/services/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Device as DeviceModel, Location as LocationModel } from "@/models";
import KiosksFormModal from "./components/KiosksFormModal.vue";
import { translations, notificationAlerts } from "@/mixins";
import moment from "moment";

let kioskDefaultValue = {
  id: "",
  "location-id": "",
  label: "",
  uuid: "",
  "ip-address": "",
  "terminal-id": "",
};

@Component({
  components: {
    KiosksItemsTable: () => import("./components/KiosksItemsTable.vue"),
    LoadingPanel: () => import("@/components/LoadingPanel.vue"),
    KiosksFormModal: () => import("./components/KiosksFormModal.vue"),
    RouteBreadCrumb: () => import("@/components/Breadcrumb/RouteBreadcrumb"),
  },
  mixins: [translations, notificationAlerts],
})
export default class Kiosks extends Vue {
  translate!: Function;
  systemErrorNotification!: Function;
  successNotification!: Function;
  itemLimit: Number = 100;
  loading: Boolean = false;
  devices: Array<DeviceModel> = [];
  locations: Array<LocationModel> = [];
  locationsLoaded: Boolean = false;
  activeKiosk: Object = kioskDefaultValue;

  $notify: any;
  $refs!: {
    kiosksFormModal: KiosksFormModal;
  };

  created() {
    this.getDevices(true);
    this.getLocations();
  }

  private async loadNewKiosks() {
    const response = await DeviceService.refreshMdm();

    this.getDevices(false);
    this.successNotification(
      "NEW KIOSKS LOADED",
      "New kiosks has been loaded!"
    );
  }

  private onSuccessfulFormSubmit() {
    this.getDevices(false);
  }

  private createItem() {
    this.$refs.kiosksFormModal.open = true;
  }

  private async getItemMdm(item: DeviceModel) {
    let response = await DeviceService.getMdmDeviceDetails(item.id);

    this.getDevices(false);
    this.successNotification(
      "DEVICE DETAILS LOADED",
      "Device details has been loaded!"
    ); 
  }

  private editItem(item: DeviceModel) {
    this.$refs.kiosksFormModal.model = {
      id: item.id.toString(),
      "location-id": item.attributes["location-id"].toString(),
      label: item.attributes.label,
      uuid: item.attributes['device-uuid'],
      "ip-address": item.attributes["ip-address"],
      "terminal-id": item.attributes["terminal-id"],
    };
    this.$refs.kiosksFormModal.open = true;
  }

  onModalClose() {
    this.$refs.kiosksFormModal.model = { ...kioskDefaultValue };
  }

  async getDevices(showLoading: boolean = true) {
    if (showLoading) {
      this.loading = true;
    }

    try {
      const response = await DeviceService.all();
      this.devices = response.data.data;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.systemErrorNotification();
    }
  }

  async getLocations() {
    try {
      const response = await LocationService.fetchLocations();
      this.locations = response.data.data;
      this.locationsLoaded = true;
    } catch (error) {
      this.systemErrorNotification();
    }
  }
}
